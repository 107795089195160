import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Storage from 'vue-ls'
let options = {
    namespace : 'vuejs',
    name : 'ls',
    storage : 'local'
}

// Element Plus
import 'element-plus/dist/index.css'

// 创建App实例
const app = createApp(App)

import { Edit, Search, Fold, Menu,StarFilled,User, Star,SwitchButton,ArrowLeft, ArrowRight,ArrowDown } from '@element-plus/icons'
import { ElButton, ElContainer, ElMain, ElRow, ElCol, ElInput } from 'element-plus'
//注册组件
app.component("edit", Edit)
app.component("search", Search)
app.component("Fold", Fold)
app.component("menu1", Menu)
app.component("StarFilled", StarFilled)
app.component("user", User)
app.component("star", Star)
app.component("switchButton", SwitchButton)
app.component("ArrowLeft", ArrowLeft)
app.component("ArrowRight", ArrowRight)
app.component("ArrowDown", ArrowDown)
app.component('ElButton',ElButton)
app.component('ElContainer',ElContainer)
app.component('ElMain',ElMain)
app.component('ElRow',ElRow)
app.component('ElCol',ElCol)
app.component('ElInput',ElInput)

app.use(Storage,options)

app.use(store)
app.use(router)
app.mount('#app')

