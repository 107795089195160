/*
 * @Descripttion:
 * @version:
 * @Company: ZYZN
 * @Author: ziyang
 * @Date: 2020-11-25 08:27:35
 */

export default {
    //prod
    defaultIndexUrl:"https://www.hiweke.com",
    //test
    // defaultIndexUrl:"http://ducong.hiweke.com",
    //dev
    // defaultIndexUrl:"http://127.0.0.1:9999",

    // 手机端端项目地址
    mobileUrl: 'https://www.hiweke.com/mobile/',

    randomStr: function (num) {
        num = num || 16;
        let result = '';
        let str = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
        for (let index = 0; index < num; index++) {
            result += str.charAt(Math.floor(Math.random() * str.length))

        }
        return result
    },
    /**
     * 将 Date 转化为指定格式的String
     * @param {*} date
     * @param {*} fmt
     */
    formatDateStr(date, fmt) {
        let o = {
            "M+": date.getMonth() + 1, //月份
            "d+": date.getDate(), //日
            "h+": date.getHours(), //小时
            "m+": date.getMinutes(), //分
            "s+": date.getSeconds(), //秒
            "q+": Math.floor((date.getMonth() + 3) / 3), //季度
            "S": date.getMilliseconds() //毫秒
        };
        if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (let k in o)
            if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return fmt;
    },
    //初始化后台省市县数据 显示在页面上
    //province省 city市 area区 例如数据： 省-市-区 (370000,370200,370214)
    getAreaString(province, city, area) {
        let str = "";
        let tt="";
        if (province != null && province != '') {
            //遍历省
            for (let [key, value] of Object.entries(this.areaList.province_list)) {
                if (key == province) {
                    str += value;
                    break;
                }
            }
        }
        if (city != null && city != '') {
            //遍历市
            for (let [key, value] of Object.entries(this.areaList.city_list)) {
                if (key == city) {
                    if(str != value)
                    str += value;
                    tt = value
                    break;
                }
            }
        }
        if (area != null && area != '') {
            //遍历区
            for (let [key, value] of Object.entries(this.areaList.county_list)) {
                if (key == area) {
                    if(tt != value)
                    str += value;
                    break;
                }
            }
        }
        return str;
    },

}
