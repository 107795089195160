import { createRouter, createWebHistory } from 'vue-router'
import global from "@/common/global"
const routes = [
  {
    path: '/',
    name: 'Index',
    component:() => import('./../views/Index.vue')
  },
  {
    path: '/search',
    // path: '/search/:val',
    name: 'Search',
    component: () => import('./../views/Search.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('./../views/news/Index.vue'),
  },
  {
    path: '/newsDetail',
    name: 'NewsDetail',
    component: () => import('./../views/news/Detail.vue')
  },
  {
    path: '/tools',
    name: 'Tools',
    component: () => import('./../views/tools/Index.vue'),
  },
  {
    path: '/toolDetail',
    name: 'ToolDetail',
    component: () => import('./../views/tools/Detail.vue')
  },
  {
    path: '/mine',
    name: 'Mine',
    component: () => import('./../views/Mine.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})
//路由守卫
router.beforeEach((to, from, next)=>{
  // 判断设备
  if(/(Android|iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)){
    //移动端
    window.location.href= global.mobileUrl
    // next()
  }else{
    //pc端
    next()
  }
})
export default router
